export const onClientEntry = () => {
  const userLang = navigator.language.substr(0, 2)

  const isPl =
    window.localStorage.language != null
      ? window.localStorage.language.toLowerCase() === "pl"
      : true

  if (
    window.location.pathname === "/en/" ||
    window.location.pathname === "/en"
  ) {
    window.location.pathname = ""
  }

  if (userLang === "pl" && isPl && window.location.pathname === "/") {
    window.location.pathname = `/pl/`
  }
}

export const onRouteUpdate = function ({ location }) {
  const isContactPage = ["/contact/", "/pl/kontakt/"].includes(
    location.pathname
  )

  if (typeof fbq === "function" && isContactPage) {
    fbq("track", "ViewContent")
  }
}
